module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Binary Stride","short_name":"Binary Stride","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"browser","icon":"/vercel/workpath1/src/images/icon.png","include_favicon":false,"legacy":false,"cache_busting_mode":"query","theme_color_in_head":true,"cacheDigest":"bd6452f966f012d032c89255b8ab3a6b"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-H8PKT5CLT3"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
